import React from 'react';
import PropTypes from 'prop-types';
import PubSub from 'pubsub-js';

import Avataaar from 'avataaars';

class Avatar extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      topType: props.topType,
      accessoriesType: props.accessoriesType,
      hatColor: props.hatColor,
      hairColor: props.hairColor,
      facialHairType: props.facialHairType,
      facialHairColor: props.facialHairColor,
      clotheType: props.clotheType,
      clotheColor: props.clotheColor,
      graphicType: props.graphicType,
      eyeType: props.eyeType,
      eyebrowType: props.eyebrowType,
      mouthType: props.mouthType,
      skinColor: props.skinColor
    };
  }

  componentDidMount () {
    PubSub.subscribe('AVATAR_CHANGE', (_msg, data) => this.onChange(data));
  }

  onChange = ({ type, value }) => {
    this.setState(prevState => {
      prevState[type] = value;
      return prevState;
    });
  }

  render() {
    return (
      <Avataaar
        style={{width: '100%', height: '100%'}}
        topType={this.state.topType}
        accessoriesType={this.state.accessoriesType}
        hatColor={this.state.hatColor}
        hairColor={this.state.hairColor}
        facialHairType={this.state.facialHairType}
        facialHairColor={this.state.facialHairColor}
        clotheType={this.state.clotheType}
        clotheColor={this.state.clotheColor}
        graphicType={this.state.graphicType}
        eyeType={this.state.eyeType}
        eyebrowType={this.state.eyebrowType}
        mouthType={this.state.mouthType}
        skinColor={this.state.skinColor}
      />
    );
  }
}

Avatar.propTypes = {
  topType: PropTypes.string,
  accessoriesType: PropTypes.string,
  hatColor: PropTypes.string,
  hairColor: PropTypes.string,
  facialHairType: PropTypes.string,
  facialHairColor: PropTypes.string,
  clotheType: PropTypes.string,
  clotheColor: PropTypes.string,
  graphicType: PropTypes.string,
  eyeType: PropTypes.string,
  eyebrowType: PropTypes.string,
  mouthType: PropTypes.string,
  skinColor: PropTypes.string
};

Avatar.defaultProps = {
  skinColor: 'Light',
  topType: 'LongHairStraight',
  accessoriesType: 'Blank',
  hatColor: 'Red',
  hairColor: 'BrownDark',
  facialHairType: 'Blank',
  facialHairColor: 'BrownDark',
  clotheType: 'BlazerShirt',
  clotheColor: 'Gray02',
  graphicType: 'Skull',
  eyeType: 'Default',
  eyebrowType: 'Default',
  mouthType: 'Default'
};
export default Avatar;
