import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  window.site.cemantix.init();
});

document.addEventListener('turbolinks:load', () => {
  window.site.cemantix.init(true);
});

window.site = {
  ...window.site || {},
  cemantix: {
    initialized: false,
    init(force = false) {
      if (!force && window.site.cemantix.initialized) return;

      window.site.cemantix.initialized = true;

      document.querySelectorAll('[data-channel-subscribe="cemantix"]').forEach(element => {
        const cemantix_id = element.dataset.cemantixId;
        const counter = document.querySelector('.js-cemantix-team-counter');

        consumer.subscriptions.create({ channel: "CemantixChannel", cemantix_id: cemantix_id }, {
          received: function(data) {
            switch(data.event) {
              case "new_player":
                if (!window.gon || window.gon.users.id != data.user.id) {
                  FLASH.success(`<strong class="font-bold">${data.user.name}</strong> vient de rejoindre la partie`);
                }

                if (data.players.length > 1) counter.classList.remove('hidden');

                counter.innerHTML = data.players.length;
                break;
              case "player_disconnected":
                if (!window.gon || window.gon.users.id != data.user.id) {
                  FLASH.alert(`<strong class="font-bold">${data.user.name}</strong> vient de quitter la partie`);
                }

                if (data.players.length < 2) counter.classList.add('hidden');

                counter.innerHTML = data.players.length;
                break;
              case "new_word":
                document.dispatchEvent(
                  new CustomEvent('new_word', { detail: data })
                );
                break;
              default:
                console.log('received', data);
                console.log('consumer.subscriptions', consumer.subscriptions)
                console.log('consumer.subscriptions.length', consumer.subscriptions.length)
            }

            window.mountComponents(window.components);
          }
        });
      });
    }
  }
}
