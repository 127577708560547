import React from 'react';
import PropTypes from 'prop-types';
import FlashContainer from 'react-alert'

import Flash from './flash';
import styles from './index.module.scss';

const IconSuccess = 'https://medias.dictaly.com/images/icon-success.png';
const IconInfo = 'https://medias.dictaly.com/images/icon-info.png';
const IconWarning = 'https://medias.dictaly.com/images/icon-warning.png';
const IconError = 'https://medias.dictaly.com/images/icon-error.png';

class FlashManager extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      flash: this.props.flash
    };

    this.defaultTime = 5000;
    this.success = this.success.bind(this);
    this.info = this.info.bind(this);
    this.error = this.error.bind(this);
    this.warning = this.warning.bind(this);
  }

  componentDidMount(){
    this.props.flash.forEach((item) => {
      if (Array.isArray(item[1])){
        item[1].forEach(msg => {
          this[item[0]](msg);
        });
      }
      else{
        this[item[0]](item[1]);
      }
    });

    global.FLASH = this;
  }

  componentWillUnmount(){
    global.FLASH = undefined;
  }

  success(msg) {
    this.msg.show(<Flash msg={msg} />, { icon: <img src={IconSuccess} />, time: this.defaultTime })
  }

  info(msg) {
    this.msg.show(<Flash msg={msg} />, { icon: <img src={IconInfo} />, time: this.defaultTime })
  }
  notice = this.info;

  warning(msg) {
    this.msg.show(<Flash msg={msg} />, { icon: <img src={IconWarning} />, time: this.defaultTime })
  }

  error(msg) {
    this.msg.show(<Flash msg={msg} />, { icon: <img src={IconError} />, time: this.defaultTime })
  }

  alert(msg) {
    this.msg.show(<Flash msg={msg} />, { icon: <img src={IconError} />, time: this.defaultTime })
  }

  render() {
    return (
      <div className={`${styles.group} js-flash-manager`}>
        <FlashContainer
          ref={a => this.msg = a}
          position="top right"
          offset="20px 15"
          theme="light"
          time={this.defaultTime}
          transition="scale"
        />
      </div>
    );
  }
}

FlashManager.propTypes = {
  flash: PropTypes.array
};

FlashManager.defaultProps = {
  flash: []
};

export default FlashManager;
