import React from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';
import Avatar from '../Avatar';

const styles = {
  multiValue: (base, _state) => ({ ...base, backgroundColor: '#E0EAEC' }),
  multiValueLabel: (base, _state) => ({ ...base, fontWeight: 'bold', fontSize: '16px' }),
  control: (base, _state) => ({
    ...base,
    height: '54px',
    'minHeight': '54px'
  }),
  valueContainer: (base, _state) => ({
    ...base,
    height: '100%'
  })
};

const formatOptionLabel = ({ value, label, avatar, role }) => (
  <div className="flex items-center justify-start text-left" key={value}>
    <span className="inline-block align-center h-10 w-10 mx-auto rounded-full bg-grey-light overflow-hidden shadow-sm mr-4 ml-0">
        <Avatar
          skinColor={avatar[0]}
          topType={avatar[1]}
          accessoriesType={avatar[2]}
          hatColor={avatar[3]}
          hairColor={avatar[4]}
          facialHairType={avatar[5]}
          facialHairColor={avatar[6]}
          clotheType={avatar[7]}
          clotheColor={avatar[8]}
          graphicType={avatar[9]}
          eyeType={avatar[10]}
          eyebrowType={avatar[11]}
          mouthType={avatar[12]}
        />
    </span>
    <span>{label}</span>
    { role == "admin" && (<span className="text-grey"> {"("}<span className="text-info font-semibold">admin</span>{")"}</span>) }
    { role == "creator" && (<span className="text-grey"> {"("}<span className="text-success font-semibold">créateur</span>{")"}</span>) }
    { role == "support" && (<span className="text-grey"> {"("}<span className="text-danger font-semibold">support</span>{")"}</span>) }
  </div>
);

class UserInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      inputValue: ""
    };

    this.onChange = this.onChange.bind(this);
    this.loadOptions = debounce(this.loadOptions.bind(this), 300);
  }

  onChange (type, option) {
    this.setState(prevState => {
      prevState[type] = option;
      return prevState;
    });
  }

  loadOptions (inputValue, callback) {
    fetch(`${this.props.endpoint}?q=${inputValue}`)
      .then(response => response.json())
      .then(data =>
        callback(
          data.filter(user => user.id !== gon.users.id).map(user =>
            (
              {
                value: user.id,
                label: user.name,
                avatar: Object.values(user.avatar),
                role: user.role
              }
            )
          )
        )
      );
  }

  render() {
    return (
      <div>
        <AsyncSelect
          styles={styles}
          isClearable
          className="w-full"
          placeholder="Choisir"
          loadOptions={this.loadOptions}
          name={this.props.input_name}
          value={this.state.value}
          onChange={value => this.onChange('value', value)}
          formatOptionLabel={formatOptionLabel}
          noOptionsMessage={() => null}
        />
      </div>
    );
  }
}

UserInput.propTypes = {
  input_name: PropTypes.string,
  value: PropTypes.shape({})
};

UserInput.defaultProps = {
  value: []
};

export default UserInput;
