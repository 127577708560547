import React from 'react';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';

class CopyText extends React.Component {
  sendFlash() {
    FLASH.success(this.props.successMessage);
  }

  render() {
    return (
      <div onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}>
        <CopyToClipboard
          text={this.props.value}
          onCopy={() => this.sendFlash() }
        >
          <span className={this.props.class} dangerouslySetInnerHTML={{ __html: this.props.text || this.props.value }}></span>
        </CopyToClipboard>
      </div>
    );
  }
}

CopyText.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string,
  class: PropTypes.string,
  successMessage: PropTypes.string
};

CopyText.defaultProps = {
  class: "",
  successMessage: "Le lien a été copié dans votre presse-papier"
};

export default CopyText;
