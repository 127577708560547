import React from 'react';
import PropTypes from 'prop-types';

class Flash extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    messageHtml() {
      return {
        __html: [".", "!", "?"].indexOf(this.props.msg[this.props.msg.length-1]) > -1 ? this.props.msg : this.props.msg + "."
      };
    }

    render() {

        return (
          <div className="flash-manager-item" dangerouslySetInnerHTML={this.messageHtml()}></div>
        );
    }
}

Flash.propTypes = {
  msg: PropTypes.string.isRequired
};

Flash.defaultProps = {};

export default Flash;

