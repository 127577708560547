require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import { mountComponents, unMountComponents } from '../utils/react';
import '../stylesheets/common.scss'
import { createPopper } from '@popperjs/core';

window.mountComponents = mountComponents;
window.unMountComponents = unMountComponents;
window.components = {
  ...window.components,
  'Avatar': require('../components/Avatar'),
  'CopyText': require('../components/CopyText'),
  'FlashManager': require('../components/FlashManager'),
  'UserInput': require('../components/UserInput')
}

window.mountComponents(window.components);

// Polyfills

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (var i = 0; i < this.length; i++) {
          callback.call(thisArg, this[i], i, this);
      }
  };
}

// Global

window.site = {
  ...window.site || {},
  history: {
    init: () => {
      const elements = document.querySelectorAll('.js-push-state, [data-prev-state-url]');

      elements.forEach(element => {
        element.removeEventListener('mouseup', event => site.history.pushState(element, event));
        element.addEventListener('mouseup', event => site.history.pushState(element, event));
      });
    },
    pushState: (element, event = null) => {
      if (event && event.target.closest(".js-modal-container .container, .js-modal-container .container-full")) return;

      const url = element.href || element.getAttribute('data-prev-state-url');

      if (url && location.href != url) {
        if (element.href) site.history.updatePrevStateAttributes();
        window.history.pushState({}, "", url);
      }
    },
    updatePrevStateAttributes: () => {
      document.querySelectorAll('[data-prev-state-url]').forEach(element => {
        element.setAttribute('data-prev-state-url', location.href);
      });
    }
  },
  accordeon: {
    init: () => {
      site.accordeon.setInitialState();

      const accordeons = document.querySelectorAll('.js-accordeon');
      accordeons.forEach(accordeon => {
        accordeon.firstElementChild.removeEventListener('change', event => site.accordeon.toggleAccordeon(event, accordeon));
        accordeon.firstElementChild.addEventListener('change', event => site.accordeon.toggleAccordeon(event, accordeon));
      })
    },
    setInitialState: () => {
      if (window.location.hash && window.location.hash != "" && window.location.hash != "#") {
        const accordeon = document.getElementById(window.location.hash.replace('#', ''));
        if (accordeon && accordeon.firstElementChild) accordeon.firstElementChild.checked = true;
      }
    },
    toggleAccordeon: (event, accordeon) => {
      site.accordeon.updateHash(event, accordeon);
      site.accordeon.replaceText(event, accordeon.querySelector('.js-accordeon-label'));
    },
    replaceText: (event, label) => {
      if (!label.getAttribute('data-replace-with')) return;
      if (!label.getAttribute('data-original-content')) label.setAttribute('data-original-content', label.innerHTML);

      if (event.target.checked) {
        label.innerHTML = label.getAttribute('data-replace-with');
      } else {
        label.innerHTML = label.getAttribute('data-original-content');
      }

    },
    updateHash: (event, accordeon) => {
      if (event.target.checked) {
        history.pushState("", document.title, `#${accordeon.id}`);
      } else {
        history.pushState("", document.title, window.location.pathname + window.location.search);
      }
    }
  },
  scrollToAnchor: {
    init: () => {
      const links = document.querySelectorAll('a[href*="#"]:not(.pagy-link)');
      links.forEach(link => {
        link.removeEventListener('click', event => site.scrollToAnchor.scrollTo(event, link))
        link.addEventListener('click', event => site.scrollToAnchor.scrollTo(event, link))
      });

      if (window.location.hash.length) {
        site.scrollTo(document.getElementById(window.location.hash.replace('#', '')))
      }
    },
    scrollTo: (event, link) => {
      if(link.hash && link.hash != "" && link.hash != "#" ){
        const element = document.getElementById(link.hash.replace('#', ''));
        if (element) {
          site.scrollTo(element);
          if (event) event.preventDefault();
          window.history.pushState({}, '', link.hash);
        }
      }
    }
  },
  getElementY(element) {
    return window.pageYOffset + element.getBoundingClientRect().top
  },
  scrollTo(element, duration = 250, elementY = null) {
    if (!element) return;

    var startingY = window.pageYOffset
    elementY = elementY || site.getElementY(element) - 90 - 20;
    // If element is close to page's bottom then window will scroll only to some position above the element.
    var targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
    var diff = targetY - startingY
    // Easing function: easeInOutCubic
    // From: https://gist.github.com/gre/1650294
    var easing = function (t) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
    var start

    if (!diff) return

    // Bootstrap our animation - it will get called right before next frame shall be rendered.
    window.requestAnimationFrame(function step(timestamp) {
      if (!start) start = timestamp
      // Elapsed miliseconds since start of scrolling.
      var time = timestamp - start
      // Get percent of completion in range [0, 1].
      var percent = Math.min(time / duration, 1)
      // Apply the easing.
      // It can cause bad-looking slow frames in browser performance tool, so be careful.
      percent = easing(percent)

      window.scrollTo(0, startingY + diff * percent)

      // Proceed with animation as long as we wanted it to.
      if (time < duration) {
        window.requestAnimationFrame(step)
      }
    })
  },
  header: {
    onScroll: ($header, navItems, avatar, menu, flashManager) => {
      if (!flashManager) flashManager = document.querySelector('.js-flash-manager');
      if (window.scrollY) {
        if (navItems && navItems.length) {
          navItems.forEach(item => {
            item.classList.add('xl:hover:bg-grey-light');
            item.classList.remove('xl:hover:bg-white', 'xl:hover:shadow-lg');
          });
        }
        if (avatar) {
          avatar.classList.add('bg-grey-mid');
          avatar.classList.remove('bg-white');
        }
        if (menu) {
          menu.classList.add('bg-grey-light');
          menu.classList.remove('bg-white', 'shadow');
        }
        $header.classList.remove('py-4');
        $header.classList.add('fixed', 'bg-white', 'shadow-xl', 'py-2');
        if (flashManager && flashManager.firstChild) flashManager.firstChild.classList.add('pt-2');
      } else {
        if (navItems && navItems.length) {
          navItems.forEach(item => {
            item.classList.add('xl:hover:bg-white', 'xl:hover:shadow-lg');
            item.classList.remove('xl:hover:bg-grey-light');
          });
        }
        if (avatar) {
          avatar.classList.add('bg-white');
          avatar.classList.remove('bg-grey-mid');
        }
        if (menu) {
          menu.classList.add('bg-white', 'shadow');
          menu.classList.remove('bg-grey-light');
        }
        $header.classList.add('py-4');
        $header.classList.remove('fixed', 'bg-white', 'shadow-xl', 'py-2');
        if (flashManager && flashManager.firstChild) flashManager.firstChild.classList.remove('pt-2');
      }
    },
    init: () => {
      // setTimeout(() => {
        const $header = document.querySelector('header.stickable');

        if ($header) {
          // $header.parentNode.style.height = `${$header.getBoundingClientRect().height}px`;
          $header.parentNode.style.height = '90px';

          const navItems = $header.querySelectorAll('.js-header-nav-item');
          const avatar = $header.querySelector('.js-header-avatar');
          const menu = $header.querySelector('.js-header-menu-label div');
          let flashManager;

          window.removeEventListener("scroll", () => site.header.onScroll($header, navItems, avatar, menu, flashManager));
          window.addEventListener("scroll", () => site.header.onScroll($header, navItems, avatar, menu, flashManager));
        }
      // }, 200);
    }
  },
  menu: {
    init: () => {
      const container = document.querySelector('.js-header-menu-container');

      if (container) {
        site.menu.input = document.querySelector('.js-header-menu-input');

        const accordeons = container.querySelectorAll('.js-accordeon');

        ["click", "touch"].forEach(function(event){
          container.removeEventListener(event, site.menu.closeMenu);
          container.addEventListener(event, site.menu.closeMenu);
          accordeons.forEach(accordeon => {
            accordeon.removeEventListener(event, event => event.stopPropagation());
            accordeon.addEventListener(event, event => event.stopPropagation());
          });
        });
      }
    },
    closeMenu(_event) {
      site.menu.input.checked = false;
    }
  },
  share: {
    init: () => {
      const items = document.querySelectorAll('.js-share-input');

      items.forEach(item => {
        const container = item.parentNode.querySelector('.js-share-container');
        const closeButton = item.parentNode.querySelector('.js-share-close');

        if (container) {
          const list = item.parentNode.querySelector('.js-share-list');

          site.share.input = item.parentNode.querySelector('.js-share-input');

          ["mouseup", "touch"].forEach(function(event){
            container.removeEventListener(event, () => site.share.closePopup(item));
            closeButton.removeEventListener(event, () => site.share.closePopup(item));
            list.removeEventListener(event, site.share.stopPropagation);
            container.addEventListener(event, () => site.share.closePopup(item));
            closeButton.addEventListener(event, () => site.share.closePopup(item));
            list.addEventListener(event, site.share.stopPropagation);
          });
        }
      })
    },
    closePopup(item) {
      item.checked = false;
    },
    stopPropagation(event) {
      if (event.target.classList.contains("js-copy-to-clipboard")) return;

      event.stopPropagation();
    }
  },
  tooltips: {
    timeout: null,
    init: (tooltips = document.querySelectorAll('.js-tooltip'), offset = [0, 8], displayDelay = 0, onCreate = () => {}) => {

      tooltips.forEach(element => {
        const tooltip = element.querySelector('.js-tooltip-element') || element.nextSibling;
        const parent = element.closest('.js-tooltip-boundary');
        let overflowModifier = {};

        if (parent) {
          overflowModifier = {
            name: 'preventOverflow',
            options: {
              boundary: parent
            }
          };
        }

        let popperInstance = null;

        function create() {
          popperInstance = createPopper(element, tooltip, {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: offset,
                },
              },
              overflowModifier
            ],
          });
        }

        function destroy() {
          if (popperInstance) {
            popperInstance.destroy();
            popperInstance = null;
          }
        }

        function show() {
          clearTimeout(site.tooltips.timeout);
          site.tooltips.timeout = setTimeout(() => {
            onCreate();
            onCreate = () => {};
            tooltip.setAttribute('data-show', '');
            create();
          }, displayDelay);
        }

        function hide() {
          clearTimeout(site.tooltips.timeout);
          tooltip.removeAttribute('data-show');
          destroy();
        }

        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];

        showEvents.forEach(event => {
          element.addEventListener(event, show);
        });

        hideEvents.forEach(event => {
          element.addEventListener(event, hide);
        });
      });
    }
  },
  chat: {
    init: () => {
      const toggleButton = document.getElementById('chat-toggle');

      if (toggleButton) {
        ["click", "touch"].forEach(function(event){
          toggleButton.removeEventListener(event, site.chat.toggle);
          toggleButton.addEventListener(event, site.chat.toggle);
        });
      }

      const loadButton = document.querySelector('.js-chat-load');

      if (loadButton) {
        ["click", "touch"].forEach(function(event){
          loadButton.removeEventListener(event, site.chat.displayLoader);
          loadButton.addEventListener(event, site.chat.displayLoader);
        });
      }
    },
    displayLoader: () => {
      const loader = document.querySelector('.js-chat-loader');

      if (loader) {
        loader.classList.remove('hidden');
      }
    },
    toggle: () => {
      const container = document.getElementById('chat');

      if (container.classList.contains('active')) {
        container.classList.remove('active');
      } else {
        container.classList.add('active');
      }
    }
  },
  twemoji: {
    init: () => {
      if (typeof global.twemoji !== 'undefined') twemoji.parse(document.body, {
        base: 'https://cdn.jsdelivr.net/gh/twitter/twemoji@latest/assets/',
        folder: 'svg',
        ext: '.svg'
      });
    }
  }
}

window.Modal = {
  init () {
    window.Modal.element = document.querySelector('.js-modal');
    window.Modal.containerElement = document.querySelector('.js-modal-container .container, .js-modal-container .container-full');
    window.Modal.setEvents();
  },
  setEvents () {
    if (window.Modal.containerElement) {
      ["click", "touch"].forEach(function(event){
        document.removeEventListener(event, window.Modal.handleClick);
        document.addEventListener(event, window.Modal.handleClick);
      });
    }
  },
  handleClick (event) {
    if (!document.body.contains(event.target)) return;

    if (
      event.target.matches(".js-modal-close") ||
      !event.target.closest(".js-modal-container .container, .js-modal-container .container-full")
    ) {
      window.Modal.close(event);
    } else if (event.target.matches(".js-modal-open")) {
      window.Modal.open();
    }
  },
  close (event = null) {
    window.Modal.element.classList.remove('block');
    window.Modal.element.classList.add('hidden');

    if (event && event.target.hasAttribute('data-prev-state-url')) window.site.history.pushState(event.target);
  },
  open() {
    window.Modal.init();
    window.Modal.element.classList.remove('hidden');
    window.Modal.element.classList.add('block');
  }
}

document.addEventListener('DOMContentLoaded', () => {
  site.twemoji.init();

  window.Modal.init();

  site.history.init();
  site.header.init();
  site.scrollToAnchor.init();
  site.accordeon.init();
  site.menu.init();
  site.share.init();
  site.tooltips.init();
  site.chat.init();
});

document.addEventListener('turbolinks:load', () => {
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({
      'event': 'pageView',
      'virtualUrl': window.location.pathname
    });
  }

  window.Modal.init();

  site.twemoji.init();
  site.history.init();
  site.header.init();
  site.scrollToAnchor.init();
  site.accordeon.init();
  site.menu.init();
  site.share.init();
  site.tooltips.init();
  site.chat.init();

  window.mountComponents(window.components);
});

document.addEventListener('render_async_load', () => {
  site.twemoji.init();
  site.history.init();
  site.header.init();
  site.scrollToAnchor.init();
  site.accordeon.init();
  site.tooltips.init();
  site.chat.init();
  window.mountComponents(window.components);
});

document.addEventListener('turbolinks:before-cache', () => {
  window.unMountComponents(window.components);
});
