import consumer from "./consumer"

document.addEventListener('DOMContentLoaded', () => {
  window.site.groupChat.init();
});

document.addEventListener('turbolinks:load', () => {
  window.site.groupChat.init();
  window.site.groupChat.scrollToLastMessage();
});

window.site = {
  ...window.site || {},
  groupChat: {
    init() {
      document.querySelectorAll('[data-channel-subscribe="conversation"]').forEach(element => {
        const conversation_id = element.dataset.conversationId;

        window.site.groupChat.scrollToLastMessage();

        consumer.subscriptions.create({ channel: "ConversationChannel", conversation_id: conversation_id }, {
          received: function(data) {
            const message = document.getElementById(`message-${data.message.slug}`);

            if (data.event === "create" && !message) {
              if (data.message.user_id == gon.users.id) {
                element.innerHTML += data.sender;
              } else {
                element.innerHTML += data.recipient;
              }
            } else if (data.event === "update" || data.event === "destroy") {
              if (data.message.user_id == gon.users.id) {
                message.innerHTML = data.sender;
              } else {
                message.innerHTML = data.recipient;
              }
            }

            window.mountComponents(window.components);
          }
        });
      });
    },
    scrollToLastMessage () {
      var last_message = document.querySelector('.js-conversation-last-message');
      if (last_message) { site.scrollTo(last_message, 10); }
    }
  }
}
